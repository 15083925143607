$(() => {
  const $form = $('.js-pageLoginForm, .js-forgotPasswordForm, .js-newPasswordForm');
  const $textfield = $form.find('.mdl-textfield');
  const $textfieldInput = $form.find('.mdl-textfield__input');
  const $loginForm = $('.js-pageLoginForm');
  const $loginValidation = $loginForm.find('.js-loginValidation');
  const $loginUsername = $loginForm.find('input[id$="username"]');
  const $loginPwd = $loginForm.find('input[id$="password"]');
  const validClass = 'is-valid';
  const invalidClass = 'is-invalid';
  const isFocused = 'is-focused';

  $textfieldInput.on('input keyup click blur', (e) => {
    const $currentInputContainer = $(e.currentTarget).closest('.inputContainer');
    const $currentTextfield = $(e.currentTarget).closest('.mdl-textfield');

    $currentInputContainer.removeClass(validClass).addClass(invalidClass);

    if ($currentTextfield.hasClass(isFocused)) {
      $currentInputContainer.removeClass(invalidClass);
    }

    if (!$textfield.hasClass(invalidClass)) {
      $currentInputContainer.addClass(validClass).removeClass(invalidClass);
    }

    setTimeout(() => {
      if (!$currentTextfield.hasClass(isFocused) && $currentTextfield.hasClass(invalidClass)) {
        $currentInputContainer.addClass(invalidClass);
      }
    }, 100);
  });

  /**
   * Login Form
   */
  $loginValidation.on('click', () => {
    let hasError = true;

    if ($loginUsername.val() !== '' && $loginPwd.val() !== '' && !$textfield.hasClass(invalidClass)) {
      hasError = false;
    } else {
      $textfieldInput.each((_idx, element) => {
        const $currentInputContainer = $(element).closest('.inputContainer');
        const $currentTextfield = $(element).closest('.mdl-textfield');

        let validField = $(element).val() !== '' && !$currentTextfield.hasClass(invalidClass);

        if (!validField) {
          $currentInputContainer.addClass(invalidClass);
          $currentTextfield.addClass(invalidClass);
          hasError = true;
        } else {
          $currentInputContainer.addClass(validClass);
        }
      });
    }

    if (hasError) {
      setTimeout(function () {
        button.clearButtonLoader();
      }, 200);
    } else {
      $loginForm.submit();
    }
  });

  /**
   * Process form and add submit event listener
   * @param {string} form - form selector
   * @param {string} field - field to check
   * @param {boolean} hasValidator - field to check
   */
  const processForm = (form, field, hasValidator = false) => {
    if (hasValidator) {
      $(`[name='${field}']`).on('input keyup click blur', (e) => {
        fieldValidator(e.currentTarget);
      });
    }

    $(form).on('submit', (e) => {
      const raw_data = $(form).serializeArray();
      const data = raw_data.reduce((obj, {name, value}) => ({...obj, [name]: value}), {});
      const $field = $(`[name='${field}']`);

      if (typeof data[field] === 'undefined' || data[field] === '' || (typeof $field.data('is-valid') !== 'undefined' && !$field.data('is-valid'))) {
        e.preventDefault();
        $field
          .closest('.mdl-textfield').removeClass('is-valid').addClass('is-invalid')
          .closest('.inputContainer').removeClass('is-valid').addClass('is-invalid');
        button.clearButtonLoader();
      }
    });
  };

  processForm('.js-forgotPasswordForm', 'request_password[email]');
  processForm('.js-newPasswordForm', 'update_password[password]', true);

  $(document).on('click', '.js-passwordToggle', (e) => {
    e.preventDefault();
    const trigger = $(e.currentTarget);
    const field = trigger.parent().find('.mdl-textfield__input');
    field.toggleClass('is-show');
    field.attr('type', field.hasClass('is-show') ? 'text' : 'password');
    trigger.toggleClass('is-show');
  });
});